.grecaptcha-badge {
  display: none;
}

.cke_contents {
  min-height: 44px;
}

* {
  color-scheme: light;
}
